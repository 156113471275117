import React, {Component} from 'react';
import {connect} from 'react-redux';

import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';

import ImageGridList from './OrderImageList';
import OrderViewEdit from './OrderViewEdit';
import CustomizedTimeline from './OrderTimeline';
import {clearAlert} from '../../actions/alertActions';
import {closeModal, clearOrder} from '../../actions/azdanActions';

class OrderView extends Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    this.props.clearAlert();
    this.props.closeModal();
    this.props.clearOrder();
  }

  render() {
    return (
      <div className={`modal fade-in-2 ${this.props.modal.editShow}`}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary mt-1">
                Order #{this.props.order.task_id} for:{' '}
                {this.props.auth.user.name}
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={this.close}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <OrderViewEdit onRef={(ref) => (this.child = ref)} />
              <div className="mt-3">
                <div className="container">
                  <h5 className="text-primary ml-2">Timeline</h5>
                  <CustomizedTimeline />
                </div>
              </div>
              <div className="mt-3">
                <div className="container">
                  <h5 className="text-primary">Customer Signature</h5>
                  <div id="sigImage">
                    {this.props.order.task_signature ? (
                      <img
                        src={`${this.props.order.task_signature}`}
                        alt="pos"
                      />
                    ) : (
                      <div>
                        <p>
                          You will see a photo of the customer's sigature after
                          the delivery is complete.
                        </p>
                        <Skeleton variant="rect" width={210} height={118} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div className="container">
                  <h5 className="text-primary">Photo</h5>
                  <div id="packageImage">
                    {this.props.order.task_picture &&
                    this.props.order.task_picture.length ? (
                      <ImageGridList images={this.props.order.task_picture} />
                    ) : (
                      <div>
                        <p>
                          You will see a photo of your delivery after the
                          delivery is complete.
                        </p>
                        <Skeleton variant="rect" width={210} height={118} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="mr-3"></div>
              <Button type="button" onClick={this.close} variant="contained">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order.order,
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapStateToProps, {clearAlert, closeModal, clearOrder})(
  OrderView,
);
