import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Fab, Button } from '@material-ui/core';
import { Print } from '@material-ui/icons';

import districts from '../../assets/uaeCities';
import { formatDate } from '../../helpers';
import { clearAlert } from '../../actions/alertActions';
import logo from '../../images/transcorpLogo.png';
import BorderColor from '@material-ui/icons/BorderColor';

import {
  editOrder,
  closeModal,
  cancelOrder,
  clearErrors,
  openCreateModal,
} from '../../actions/azdanActions';

const cities = Object.keys(districts);

class OrderViewEdit extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      task_id: '',
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_conscountryname: 'UAE',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: '',
      task_completeaftertime: '',
      task_completebeforetime: '',
      task_awb: '',
      task_smsnotification: '',
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      task_amazonsfname: '',
      task_amazonshline1: '',
      task_amazonshcity: '',
      task_amazonshcontactph: '',
      task_hangers: '',
      task_shipmentpackage: '',
      task_type: '',

      errors: {},
      isEditable: false,
      isToggleComplete: false,
    };

    this.onChange = this.onChange.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
    this.enableEdit = this.enableEdit.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.closeComplete = this.closeComplete.bind(this);
    this.toggleComplete = this.toggleComplete.bind(this);

    this.toPrint = React.createRef();
  }

  // populates edit form with existing order details
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.alert) {
      if (nextProps.alert.success === true) {
        this.setState({ isEditable: false, isToggleComplete: false });
      }
    }

    if (nextProps.order.order) {
      this.setState({
        id: nextProps.order.order._id,
        task_id: nextProps.order.order.task_id,
        task_consigneename: nextProps.order.order.task_consigneename,
        task_conscontactphone: nextProps.order.order.task_conscontactphone,
        task_consaddline1: nextProps.order.order.task_consaddline1,
        task_conscity: nextProps.order.order.task_conscity,
        task_consdistrict: nextProps.order.order.task_consdistrict,
        task_totalshipqty: nextProps.order.order.task_totalshipqty,
        task_details: nextProps.order.order.task_details,
        task_notes: nextProps.order.order.task_notes,
        task_codamount: nextProps.order.order.task_codamount,
        task_completeafterdate: nextProps.order.order.task_completeafterdate,
        task_completeaftertime: nextProps.order.order.task_completeaftertime,
        task_completebeforetime: nextProps.order.order.task_completebeforetime,
        task_awb: nextProps.order.order.task_awb,
        task_conscountryname: 'UAE',
        task_smsnotification: nextProps.order.order.task_smsnotification,
        task_shippaymentmethod: nextProps.order.order.task_shippaymentmethod,
        task_customerorderno: nextProps.order.order.task_customerorderno,
        task_totaldecgrossw: nextProps.order.order.task_totaldecgrossw,
        task_volume: nextProps.order.order.task_volume,
        task_deliverytype: nextProps.order.order.task_deliverytype,
        task_amazonsfname: nextProps.order.order.task_amazonsfname,
        task_amazonshline1: nextProps.order.order.task_amazonshline1,
        task_amazonshcity: nextProps.order.order.task_amazonshcity,
        task_amazonshcontactph: nextProps.order.order.task_amazonshcontactph,
        task_hangers: nextProps.order.order.task_hangers,
        task_shipmentpackage: nextProps.order.order.task_shipmentpackage,
        task_type: nextProps.order.order.task_type,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.modal !== prevProps &&
      !this.props.modal.editShow &&
      this.state.isEditable
    ) {
      this.setState({
        isEditable: false,
        isToggleComplete: false,
      });
    }
  }

  // @onChange
  onChange(e) {
    if (
      e.target.name === 'task_completeafterdate' ||
      e.target.name === 'task_completeaftertime' ||
      e.target.name === 'task_completebeforetime'
    ) {
      if (e.target.value === '') {
        return;
      } else {
        let completeAfterTime = this.state.task_completeaftertime;

        switch (e.target.name) {
          case 'task_completeafterdate':
            this.setState({
              task_completeafterdate: e.target.value,
            });

            break;

          case 'task_completeaftertime':
            this.setState({
              task_completeaftertime: e.target.value,
            });

            break;

          case 'task_completebeforetime':
            if (completeAfterTime < e.target.value) {
              this.setState({
                task_completebeforetime: e.target.value,
              });
            }

            break;

          default:
            console.log('Oh my...');
        }

        return;
      }
    } else if (e.target.name === 'task_consigneename') {
      const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

      if (e.target.value === '') {
        this.setState({ [e.target.name]: e.target.value });
      } else if (regex.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
      return;
    } else if (e.target.name === 'task_conscontactphone') {
      const regex = /[-\d ()+]+$/u;

      if (e.target.value === '') {
        this.setState({ [e.target.name]: e.target.value });
      } else if (regex.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
      return;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  toggleCheck(e) {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  enableEdit() {
    this.setState({
      isEditable: true,
      isToggleComplete: false,
    });
  }

  toggleComplete() {
    this.setState({
      isToggleComplete: true,
      isEditable: false,
    });
  }

  closeEdit() {
    const { order } = this.props.order;

    this.setState({
      task_consigneename: order.task_consigneename,
      task_conscontactphone: order.task_conscontactphone,
      task_consaddline1: order.task_consaddline1,
      task_conscity: order.task_conscity,
      task_consdistrict: order.task_consdistrict,
      task_totalshipqty: order.task_totalshipqty,
      task_details: order.task_details,
      task_notes: order.task_notes,
      task_codamount: order.task_codamount,
      task_completeafterdate: order.task_completeafterdate,
      task_completeaftertime: order.task_completeaftertime,
      task_completebeforetime: order.task_completebeforetime,
      task_awb: order.task_awb,
      task_smsnotification: order.task_smsnotification,
      task_shippaymentmethod: order.task_shippaymentmethod,
      task_customerorderno: order.task_customerorderno,
      task_totaldecgrossw: order.task_totaldecgrossw,
      task_volume: order.task_volume,
      task_deliverytype: order.task_deliverytype,
      task_amazonsfname: order.task_amazonsfname,
      task_amazonshline1: order.task_amazonshline1,
      task_amazonshcity: order.task_amazonshcity,
      task_amazonshcontactph: order.task_amazonshcontactph,
      task_hangers: order.task_hangers,
      task_shipmentpackage: order.task_shipmentpackage,
      task_type: order.task_type,

      isEditable: false,
    });

    this.props.clearErrors();
    this.props.closeModal();
  }

  closeComplete() {
    this.setState({ isToggleComplete: false });
  }

  // @submitEdit
  submitEdit() {
    const { order } = this.props.order;

    this.props.clearAlert();

    if (order) {
      const editOrder = {
        id: this.state.id,
        task_id: this.state.task_id,
        task_consigneename: this.state.task_consigneename,
        task_conscontactphone: this.state.task_conscontactphone,
        task_consaddline1: this.state.task_consaddline1,
        task_conscity: this.state.task_conscity,
        task_consdistrict: this.state.task_consdistrict,
        task_totalshipqty: this.state.task_totalshipqty,
        task_details: this.state.task_details,
        task_notes: this.state.task_notes,
        task_codamount: this.state.task_codamount,
        task_completeafterdate: formatDate(this.state.task_completeafterdate),
        task_completeaftertime: this.state.task_completeaftertime,
        task_completebeforetime: this.state.task_completebeforetime,
        task_conscountryname: this.state.task_conscountryname,
        task_awb: this.state.task_awb,
        task_smsnotification: this.state.task_smsnotification,
        task_shippaymentmethod: this.state.task_shippaymentmethod,
        task_customerorderno: this.state.task_customerorderno,
        task_totaldecgrossw: this.state.task_totaldecgrossw,
        task_volume: this.state.task_volume,
        task_deliverytype: this.state.task_deliverytype,
        task_amazonsfname: this.state.task_amazonsfname,
        task_amazonshline1: this.state.task_amazonshline1,
        task_amazonshcity: this.state.task_amazonshcity,
        task_amazonshcontactph: this.state.task_amazonshcontactph,
        task_hangers: this.state.task_hangers,
        task_shipmentpackage: this.state.task_shipmentpackage,
        task_type: this.state.task_type,
      };
      if (window.confirm('Are you sure you want to edit this job order?')) {
        this.props.editOrder(editOrder);
      }
    }
  }

  cancelOrder() {
    this.props.cancelOrder(this.props.order.order);
  }

  render() {
    const { errors, isToggleComplete } = this.state;
    const { auth } = this.props;
    const { order, submitInProgress } = this.props.order;
    let { isEditable } = this.state;
    // isEditable = isEditable && order.task_status === '1';
    // Typeahead suggestions
    let districtOptions = [];
    if (this.state.task_conscity) {
      for (let i in districts[this.state.task_conscity]) {
        districtOptions.push(districts[this.state.task_conscity][i].geofence);
      }
    }

    let editControls = null;

    if (order.task_status === '1') {
      editControls = (
        <Button
          onClick={this.enableEdit}
          id="editJobOrderBtn"
          variant="contained"
          color="primary"
          className="mr-2 mb-3 tcorp-print-hide"
        >
          Edit Order
        </Button>
      );

      if (isEditable && !isToggleComplete) {
        editControls = (
          <Button
            disabled={submitInProgress ? true : false}
            variant="contained"
            color="secondary"
            type="button"
            className="mr-2 mb-3 tcorp-print-hide"
            onClick={this.submitEdit}
          >
            Confirm Edit
          </Button>
        );
      }
    }

    let deleteControl = null;
    if (order.task_status === '1') {
      deleteControl =
        isEditable || isToggleComplete ? null : (
          <Button
            disabled={submitInProgress ? true : false}
            title="Cancel Order"
            className="mb-3 tcorp-print-hide"
            onClick={this.cancelOrder}
            variant="contained"
            color="secondary"
          >
            Cancel Order
          </Button>
        );
    }

    // @return
    return (
      <div ref={this.toPrint}>
        <div className="container row">
          <div className="tcorp-print-element">
            <div>
              <img
                src={logo}
                alt="Logo print"
                style={{ width: '10rem', height: 'auto', margin: '1rem' }}
              />
            </div>
            <h2 className="text-primary">
              Editing Order #{this.props.order.task_id} for: {auth.user.name}
            </h2>
          </div>

          <div className="d-block">
            {order.task_status === '1' ? editControls : null}

            {editControls !== null ? (
              isEditable ? (
                <Button
                  disabled={submitInProgress ? true : false}
                  id="editBack"
                  type="button"
                  variant="contained"
                  className="mr-2 mb-3"
                  onClick={this.closeEdit}
                >
                  Back
                </Button>
              ) : null
            ) : null}
          </div>

          <div className="d-block mr-2">{deleteControl}</div>
          {/* @print */}
          {isEditable || isToggleComplete ? null : (
            <>
              <Button
                data-test="open-create-order"
                variant="contained"
                type="button"
                color="primary"
                style={{ height: '36px' }}
                onClick={this.props.openCreateModal}
              >
                <BorderColor style={{ padding: '3px 2px 4px' }} />
                &nbsp; Clone Order
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Fab
                    size="small"
                    title="Print Order"
                    type="button"
                    color="primary"
                    className="shadow-sm mb-3 mx-2 tcorp-print-hide"
                  >
                    <Print />
                  </Fab>
                )}
                content={() => this.toPrint.current}
              />
            </>
          )}
        </div>

        <form noValidate className="px-2">
          <h5 className="text-primary">Recipient</h5>

          <div className="row">
            {/* @task_consigneename */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Customer Name<em> - Required</em>
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_consigneename,
                  })}
                  placeholder=""
                  name="task_consigneename"
                  value={this.state.task_consigneename}
                  onChange={this.onChange}
                  maxLength="55"
                  required
                />

                {errors.task_consigneename && (
                  <div className="invalid-feedback">
                    {errors.task_consigneename}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Customer Name</small>
                <p>{order.task_consigneename}</p>
              </div>
            )}

            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Customer Mobile<em> - Required</em>
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_conscontactphone,
                  })}
                  placeholder=""
                  name="task_conscontactphone"
                  value={this.state.task_conscontactphone}
                  onChange={this.onChange}
                  maxLength="20"
                  required
                />

                {errors.task_conscontactphone && (
                  <div className="invalid-feedback">
                    {errors.task_conscontactphone}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Customer Mobile</small>
                <p>{order.task_conscontactphone}</p>
              </div>
            )}
          </div>

          <div className="dropdown-divider" />

          <h5 className="text-primary">Sender</h5>

          <div className="row">
            {/* @merchant name */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From Name</small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_amazonsfname,
                  })}
                  placeholder=""
                  name="task_amazonsfname"
                  value={this.state.task_amazonsfname}
                  onChange={this.onChange}
                  maxLength="55"
                  required
                />

                {errors.task_amazonsfname && (
                  <small className="text-danger" data-test="name-error">
                    {errors.task_amazonsfname}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From Name</small>
                <p>{order.task_amazonsfname}</p>
              </div>
            )}
            {/* @mechant Mobile */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From Phone</small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_amazonshcontactph,
                  })}
                  placeholder=""
                  name="task_amazonshcontactph"
                  value={this.state.task_amazonshcontactph}
                  onChange={this.onChange}
                  maxLength="20"
                  required
                />

                {errors.task_amazonshcontactph && (
                  <small className="invalid-feedback" data-test="mobile-error">
                    {errors.task_amazonshcontactph}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From Phone</small>
                <p>{order.task_amazonshcontactph}</p>
              </div>
            )}
          </div>
          <div className="row">
            {/* @merchant address */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Ship From Address
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_amazonshline1,
                  })}
                  name="task_amazonshline1"
                  value={this.state.task_amazonshline1}
                  onChange={this.onChange}
                  required
                />

                {errors.task_amazonshline1 && (
                  <small className="text-danger" data-test="name-error">
                    {errors.task_amazonshline1}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Ship From Address
                </small>
                <p>{order.task_amazonshline1}</p>
              </div>
            )}
            {/* @merchant city */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From City</small>
                <Typeahead
                  ref={typeahead => (this.typeahead = typeahead)}
                  inputProps={{ 'data-test': 'city-input' }}
                  id="task_amazonshcity"
                  name="task_amazonshcity"
                  value={this.state.task_amazonshcity}
                  onInputChange={value => {
                    this.setState({ task_amazonshcity: value });
                  }}
                  onChange={value => {
                    if (value !== undefined) {
                      this.setState({ task_amazonshcity: value[0] });
                    }
                  }}
                  options={cities}
                  required
                />

                {errors.task_amazonshcity && (
                  <small className="invalid-feedback" data-test="mobile-error">
                    {errors.task_amazonshcity}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Ship From City</small>
                <p>{order.task_amazonshcity}</p>
              </div>
            )}
          </div>

          <div className="row">
            {/* @hangers */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Hangers</small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_hangers,
                  })}
                  name="task_hangers"
                  value={this.state.task_hangers}
                  onChange={this.onChange}
                  maxLength="5"
                  required
                />

                {errors.task_hangers && (
                  <small className="text-danger" data-test="name-error">
                    {errors.task_hangers}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">Hangers</small>
                <p>{order.task_hangers}</p>
              </div>
            )}
            {/* @Shipment Packages */}
            {isEditable ? (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Shipment Packages - <em>Separate with comas</em>
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_shipmentpackage,
                  })}
                  name="task_shipmentpackage"
                  value={this.state.task_shipmentpackage}
                  onChange={this.onChange}
                  required
                />

                {errors.task_shipmentpackage && (
                  <small className="invalid-feedback" data-test="mobile-error">
                    {errors.task_shipmentpackage}
                  </small>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-6">
                <small className="form-text text-muted">
                  Shipment Packages
                </small>
                <p>{order.task_shipmentpackage}</p>
              </div>
            )}
          </div>

          <div className="dropdown-divider" />

          <h5 className="text-primary mt-3">Order Details</h5>

          <div className="row">
            {/* @Delivery Date */}
            {isEditable ? (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">
                  Delivery Date<em> - Required</em>
                </small>
                <input
                  type="date"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_completeafterdate,
                  })}
                  name="task_completeafterdate"
                  value={this.state.task_completeafterdate}
                  onChange={this.onChange}
                  required
                />

                {errors.task_completeafterdate && (
                  <div className="invalid-feedback">
                    {errors.task_completeafterdate}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">Delivery Date</small>
                <p>{order.task_completeafterdate}</p>
              </div>
            )}
            {/* @Deliver After Time */}
            {isEditable ? (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">
                  Complete After<em> - Required</em>
                </small>
                <input
                  type="time"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_completeaftertime,
                  })}
                  name="task_completeaftertime"
                  value={this.state.task_completeaftertime}
                  onChange={this.onChange}
                  required
                />

                {errors.task_completeaftertime && (
                  <div className="invalid-feedback">
                    {errors.task_completeaftertime}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">Complete After</small>
                <p>{order.task_completeaftertime}</p>
              </div>
            )}
            {/* @Complete Before Time*/}
            {isEditable ? (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">
                  Complete Before<em> - Required</em>
                </small>
                <input
                  type="time"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_completebeforetime,
                  })}
                  name="task_completebeforetime"
                  value={this.state.task_completebeforetime}
                  onChange={this.onChange}
                  min={this.state.task_completeaftertime}
                  required
                />

                {errors.task_completebeforetime && (
                  <div className="invalid-feedback">
                    {errors.task_completebeforetime}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">Complete Before</small>
                <p>{order.task_completebeforetime}</p>
              </div>
            )}
          </div>

          <div className="row">
            {/* @Package Details */}
            {isEditable ? (
              <div className="form-group col-12">
                <small className="form-text text-muted">Package Details</small>

                <textarea
                  className="form-control"
                  name="task_details"
                  id="task_details"
                  onChange={this.onChange}
                  value={this.state.task_details}
                />
              </div>
            ) : (
              <div className="form-group col-12">
                <small className="form-text text-muted">Package Details</small>
                <textarea
                  readOnly
                  className="form-control"
                  name="task_details"
                  id="ReadOnly"
                  value={order.task_details}
                />
              </div>
            )}
          </div>
          <div className="row">
            {/* @No. of Packages */}
            {isEditable ? (
              <div className="form-group col-lg-3 pr-lg-1">
                <small className="form-text text-muted">
                  No. of Packages<em> - Required</em>
                </small>
                <input
                  type="number"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_totalshipqty,
                  })}
                  name="task_totalshipqty"
                  value={this.state.task_totalshipqty}
                  onChange={this.onChange}
                  min="1"
                  required
                />

                {errors.task_totalshipqty && (
                  <div className="invalid-feedback">
                    {errors.task_totalshipqty}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3">
                <small className="form-text text-muted">No. of Packages</small>
                <p>{order.task_totalshipqty}</p>
              </div>
            )}
            {/* @Weight */}
            {isEditable ? (
              <div className="form-group col-lg-3 pr-lg-1">
                <small className="form-text text-muted">Weight</small>
                <input
                  type="number"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_totaldecgrossw,
                  })}
                  name="task_totaldecgrossw"
                  value={this.state.task_totaldecgrossw}
                  onChange={this.onChange}
                  min="0"
                />

                {errors.task_totaldecgrossw && (
                  <div className="invalid-feedback">
                    {errors.task_totaldecgrossw}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3">
                <small className="form-text text-muted">Weight</small>
                <p>{order.task_totaldecgrossw}</p>
              </div>
            )}
            {/* @Volume */}
            {isEditable ? (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Volume</small>
                <input
                  className={classnames('form-control', {
                    'is-invalid': errors.task_volume,
                  })}
                  id="task_volume"
                  name="task_volume"
                  value={this.state.task_volume}
                  onChange={this.onChange}
                  type="number"
                  min="0"
                />

                {errors.task_volume && (
                  <div className="invalid-feedback">{errors.task_volume}</div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Volume</small>
                <p>{order.task_volume}</p>
              </div>
            )}

            {/* @AWB */}
            <div className="form-group col-lg-3 px-lg-1">
              <small className="form-text text-muted">AWB</small>
              <p>{order.task_awb}</p>
            </div>
          </div>

          <div className="row">
            {/* @Reference Number */}
            {isEditable ? (
              <div className="form-group col-lg-3 pr-lg-1">
                <small className="form-text text-muted">Reference Number</small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_customerorderno,
                  })}
                  name="task_customerorderno"
                  value={this.state.task_customerorderno}
                  onChange={this.onChange}
                />

                {errors.task_customerorderno && (
                  <div className="invalid-feedback">
                    {errors.task_customerorderno}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3">
                <small className="form-text text-muted">Reference Number</small>
                <p>{order.task_customerorderno}</p>
              </div>
            )}
            {/* @Payment to be Collected */}
            {isEditable ? (
              <div className="form-group col-lg-3 pr-lg-1">
                <small className="form-text text-muted">
                  Payment to be Collected
                </small>
                <input
                  type="number"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_codamount,
                  })}
                  name="task_codamount"
                  value={this.state.task_codamount}
                  onChange={this.onChange}
                  min="0"
                />

                {errors.task_codamount && (
                  <div className="invalid-feedback">
                    {errors.task_codamount}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3">
                <small className="form-text text-muted">
                  Payment to be Collected
                </small>
                <p>{order.task_codamount}</p>
              </div>
            )}
            {/* @Payment Method */}
            {isEditable ? (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Mode of Payment</small>
                <select
                  className={classnames('form-control', {
                    'is-invalid': errors.task_shippaymentmethod,
                  })}
                  id="task_shippaymentmethod"
                  name="task_shippaymentmethod"
                  value={this.state.task_shippaymentmethod}
                  onChange={this.onChange}
                >
                  <option value="" defaultValue></option>
                  <option value="cash">Cash</option>
                  <option value="card">Card</option>
                </select>

                {errors.task_volume && (
                  <div className="invalid-feedback">
                    {errors.task_shippaymentmethod}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Mode of Payment</small>
                <p>{order.task_shippaymentmethod}</p>
              </div>
            )}

            {/* @Delivery Type */}
            {isEditable ? (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Delivery Type</small>
                <select
                  className={classnames('form-control', {
                    'is-invalid': errors.task_deliverytype,
                  })}
                  id="task_deliverytype"
                  name="task_deliverytype"
                  value={this.state.task_deliverytype}
                  onChange={this.onChange}
                  required
                >
                  <option value="nextDay" defaultValue>
                    Next Day
                  </option>
                  <option value="sameDay">Same Day</option>
                </select>

                {errors.task_deliverytype && (
                  <div className="invalid-feedback">
                    {errors.task_deliverytype}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Delivery Type</small>
                <p>{order.task_deliverytype}</p>
              </div>
            )}
          </div>

          <div className="row">
            {/* @Order Type */}
            {isEditable ? (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Order Type</small>
                <select
                  className={classnames('form-control', {
                    'is-invalid': errors.task_deliverytype,
                  })}
                  id="task_type"
                  name="task_type"
                  value={this.state.task_type}
                  onChange={this.onChange}
                  required
                >
                  <option value="2" defaultValue>
                    Delivery
                  </option>
                  <option value="1">Pick Up</option>
                </select>

                {errors.task_type && (
                  <div className="invalid-feedback">{errors.task_type}</div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-3 px-lg-1">
                <small className="form-text text-muted">Order Type</small>
                <p>{order.task_type === 1 ? 'Pickup' : 'Delivery'}</p>
              </div>
            )}
          </div>

          <div className="dropdown-divider" />

          {/* @address */}
          <div className="row">
            {isEditable ? (
              <div className="form-group col-lg-9">
                <small className="form-text text-muted">
                  Address<em> - Required</em>
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_consaddline1,
                  })}
                  name="task_consaddline1"
                  value={this.state.task_consaddline1}
                  onChange={this.onChange}
                  maxLength="400"
                  required
                />

                {errors.task_consaddline1 && (
                  <div className="invalid-feedback">
                    {errors.task_consaddline1}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-9">
                <small className="form-text text-muted">Address</small>
                <p>{order.task_consaddline1}</p>
              </div>
            )}
            {isEditable ? (
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  SMS Notifications
                </small>
                <div className="sms-container">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="task_smsnotification"
                    name="task_smsnotification"
                    checked={this.state.task_smsnotification}
                    onChange={this.toggleCheck}
                  />
                </div>
              </div>
            ) : (
              <div className="form-group col-lg-3">
                <small className="form-text text-muted">
                  SMS Notifications
                </small>
                <p>{order.task_smsnotification}</p>
              </div>
            )}
          </div>
          <div className="row">
            {isEditable ? (
              <div className="form-group col-lg-4 px-lg-1">
                <small className="form-text text-muted">
                  City/Emirate<em> - Required</em>
                </small>
                <Typeahead
                  ref={typeahead => (this.typeahead = typeahead)}
                  className={classnames({
                    'red-border': errors.task_conscity,
                  })}
                  id="task_conscity"
                  name="task_conscity"
                  defaultInputValue={this.state.task_conscity}
                  value={this.state.task_conscity}
                  onInputChange={value => {
                    this.setState({ task_conscity: value });
                  }}
                  onChange={value => {
                    if (value !== undefined) {
                      this.setState({ task_conscity: value[0] });
                    }
                  }}
                  options={cities}
                  required
                />

                {errors.task_conscity && (
                  <div className="invalid-feedback">{errors.task_conscity}</div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">
                  City/Emirate<em> - Required</em>
                </small>
                <p>{order.task_conscity}</p>
              </div>
            )}

            {isEditable ? (
              <div className="form-group col-lg-4 pl-lg-1">
                <small className="form-text text-muted">
                  District/Area<em> - Required</em>
                </small>
                <Typeahead
                  ref={typeahead => (this.typeahead = typeahead)}
                  disabled={this.state.task_conscity === '' ? true : false}
                  className={classnames({
                    'red-border': errors.task_consdistrict,
                  })}
                  id="task_consdistrict"
                  name="task_consdistrict"
                  defaultInputValue={this.state.task_consdistrict}
                  value={this.state.task_consdistrict}
                  onInputChange={value => {
                    this.setState({ task_consdistrict: value });
                  }}
                  onChange={value => {
                    if (value !== undefined) {
                      this.setState({ task_consdistrict: value[0] });
                    }
                  }}
                  options={districtOptions}
                  required
                />

                {errors.task_consdistrict && (
                  <div className="invalid-feedback">
                    {errors.task_consdistrict}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">District/Area</small>
                <p>{order.task_consdistrict}</p>
              </div>
            )}

            {/* @country */}
            {isEditable ? (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">
                  Country<em> - Required</em>
                </small>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.task_conscountryname,
                  })}
                  name="task_conscountryname"
                  value={this.state.task_conscountryname}
                  onChange={this.onChange}
                  maxLength="400"
                  required
                />

                {errors.task_conscountryname && (
                  <div className="invalid-feedback">
                    {errors.task_conscountryname}
                  </div>
                )}
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <small className="form-text text-muted">Country</small>
                <p>{order.task_conscountryname}</p>
              </div>
            )}
          </div>

          <div className="dropdown-divider" />

          <div className="row">
            {isEditable ? (
              <div className="form-group col-12">
                <small className="form-text text-muted">Delivery Notes</small>

                <textarea
                  className="form-control"
                  name="task_notes"
                  id="task_notes"
                  onChange={this.onChange}
                  value={this.state.task_notes}
                />

                {errors.task_notes && (
                  <div className="invalid-feedback">{errors.task_notes}</div>
                )}
              </div>
            ) : (
              <div className="form-group col-12">
                <small className="form-text text-muted">Notes</small>
                <p>{order.task_notes}</p>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  errors: state.errors.orderErrors,
  success: state.success,
  auth: state.auth,
  order: state.order,
  modal: state.modal,
});

export default connect(mapStateToProps, {
  editOrder,
  cancelOrder,
  closeModal,
  clearErrors,
  clearAlert,
  openCreateModal,
})(OrderViewEdit);
