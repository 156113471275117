import React, {Component} from 'react';
import ReactToPrint from 'react-to-print';
import {connect} from 'react-redux';
import {QRCodeSVG} from 'qrcode.react';
import Barcode from 'react-barcode';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

import LabelHead from '../../images/tcorpLabelLogo.png';
import logo from '../../images/tcorp-favicon.png';
import largeLogo from '../../images/transcorpLogo.png';
import LabelSizeSwitch from './LableSizeSwitch';

const stickerLabel = {
  width: '31%',
  height: '90.5mm',
  // border: '1px solid #555',
  // marginBottom: '.5rem',
  // border: '1px solid #555',
  marginRight: '4.6mm',
  boxSizing: 'border-box',
};

const stickerText = {
  color: '#000',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
  fontSize: '16px',
};

const lastStickerLabelRow = {
  width: '100%',
  height: '18%',
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const stickerPageBreak = {
  height: '4.3mm',
  // backgroundColor: 'red',
  width: '100%',
  pageBreakBefore: 'auto',
  display: 'block',
  marginBottom: '4.875mm',
};
const printStickerContainer = {
  // width: '210mm',
  // height: '297mm',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  marginTop: '4.875mm',
};
const tcorpStickerLCol = {
  width: '50%',
  textAlign: 'center',
  // borderTop: '1px solid #555',
  boxSizing: 'border-box',
};

const tcorpStickerRCol = {
  width: '50%',
  textAlign: 'center',
  // borderTop: '1px solid #555',
  boxSizing: 'border-box',
};
const tcorpStickerRow = {
  width: '100%',
  height: '8%',
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};
const tcorpStickerNameRow = {
  width: '100%',
  height: '8%',
  display: 'flex',
  justifyContent: 'center',
  // borderTop: '1px solid #555',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};
const tcorpStickerCityCol = {
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // borderBottom: '1px solid #555',
  boxSizing: 'border-box',
};

const tcorpStickerAreaCol = {
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // borderBottom: '1px solid #555',
  boxSizing: 'border-box',
};

// ////////////////////
// small label styling
const tcorpLabel = {
  width: '6.8cm',
  height: '7.6cm',
  border: '1px solid #555',
  marginBottom: '2mm',
  marginRight: '2mm',
  boxSizing: 'border-box',
};

const tcorpLabelLCol = {
  width: '50%',
  textAlign: 'center',
  border: '1px solid #555',
  boxSizing: 'border-box',
};

const tcorpLabelRCol = {
  width: '50%',
  textAlign: 'center',
  border: '1px solid #555',
  boxSizing: 'border-box',
};
const tcorpLabelCityCol = {
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2.5rem',
  border: '1px solid #555',
  boxSizing: 'border-box',
};

const tcorpLabelAreaCol = {
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '2.5rem',
  border: '1px solid #555',
  boxSizing: 'border-box',
};

const lastLabelRow = {
  width: '100%',
  height: '16%',
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};
const tcorpLabelRow = {
  width: '100%',
  height: '8%',
  display: 'flex',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};
const tcorpNameRow = {
  width: '100%',
  height: '8%',
  display: 'flex',
  justifyContent: 'center',
  border: '1px solid #555',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const barcode = {
  margin: '3px auto',
  paddingTop: '3px',
  height: '42%',
  textAlign: 'center',
};

const barcodeDiv = {
  border: '1px solid #555',
};

const printContainer = {
  margin: '20mm',
  width: '21cm',
  height: '29.7cm',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
};

const blackText = {
  color: '#000',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const nameText = {
  color: '#000',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const logoImage = {
  maxWidth: '100px',
  width: '100px',
  boxSizing: 'border-box',
};

const pageBreak = {
  height: '39.3mm',
  marginBottom: '20mm',
  // backgroundColor: 'red',
  width: '100%',
  pageBreakBefore: 'auto',
  display: 'block',
};
// ////////////////////
// large label styling
const largeLabel = {
  width: '10.2cm',
  height: '30.4cm',
  border: '1px solid #555',
  marginBottom: '.5rem',
  marginRight: '1.7mm',
  boxSizing: 'border-box',
};

const largeLabelRow = {
  width: '100%',
  height: '16%',
  display: 'flex',
  overflow: 'hidden',
  boxSizing: 'border-box',
  border: '1px solid black',
  padding: '10px',
};

const largeLabelLogoRow = {
  width: '100%',
  height: '16%',
  textAlign: 'center',
  overflow: 'hidden',
  boxSizing: 'border-box',
  border: '1px solid black',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const largeLogoImage = {
  maxWidth: '300px',
  width: '300px',
};

const largeLabelBarcodeRow = {
  width: '100%',
  height: '16%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  boxSizing: 'border-box',
  border: '1px solid black',
  padding: '10px',
};

const largeLabelQrcodeRow = {
  width: '100%',
  height: '20%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  boxSizing: 'border-box',
  border: '1px solid black',
  padding: '10px',
};

const largeLabelQrcode = {
  paddingTop: '3px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
};

const largeText = {
  color: '#000',
  fontSize: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const largeHeadText = {
  color: '#000',
  fontSize: '15px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
  lineHeight: '1.9',
};

const largeWrapText = {
  color: '#000',
  fontSize: '20px',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const largeBoldText = {
  color: '#000',
  fontSize: '22px',
  fontWeight: 'bold',
  whiteSpace: 'wrap',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const largePageBreak = {
  height: '45.1mm',
  marginBottom: '20mm',
  // backgroundColor: 'red',
  width: '100%',
  pageBreakBefore: 'auto',
  display: 'block',
};

// labelSize false === small labels
class PrintOrderLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      labelSize: 'Small',
    };
    this.toPrint = React.createRef();
  }

  handleLabelSize = (e) => {
    this.setState({
      labelSize: e.target.value,
    });
  };

  componentDidMount() {
    const {orders, selected} = this.props.location;
    const ordersToPrint = [];

    if (selected) {
      selected.forEach((select) => {
        orders.forEach((order) => {
          if (order._id === select) {
            return ordersToPrint.push(order);
          }
        });
      });
      this.setState({orders: ordersToPrint});
    }
  }

  render() {
    const {orders} = this.state;
    let count = 0;

    let label =
      this.state.labelSize === 'Large' ? (
        <div className="tcorp-print-container">
          <div ref={this.toPrint} style={printContainer}>
            {orders &&
              orders.map((order, index) => (
                <>
                  {order.task_shipmentpackage.map((awbNumber, i) => (
                    <>
                      <div
                        className="tcorp-label"
                        style={largeLabel}
                        key={'box' + i}
                      >
                        {/* top row */}
                        <div className="label-card" style={largeLabelLogoRow}>
                          {/* logo */}
                          <div>
                            <img
                              style={largeLogoImage}
                              src={largeLogo}
                              alt="Transcorp Label Header"
                            />
                          </div>

                          <div style={largeText}>
                            Delivery Date:{' '}
                            {moment(
                              order.task_completeafterdate,
                              'YYYY-MM-DD',
                            ).format('YYYY-MM-DD')}
                          </div>
                          <div style={largeText}>
                            Deliver After:
                            {moment(
                              order.task_completeaftertime,
                              'HH:mm',
                            ).format('HH:mm')}
                          </div>
                          <div style={largeText}>
                            Deliver Before:
                            {moment(
                              order.task_completebeforetime,
                              'HH:mm',
                            ).format('HH:mm')}
                          </div>
                        </div>

                        {/* FROM: */}
                        <div style={largeLabelRow}>
                          <div style={{width: '19%'}}>
                            <span style={largeHeadText}>From: </span>
                          </div>
                          <div style={{width: '81%'}}>
                            <div style={largeText}>
                              {order.task_amazonsfname ||
                                this.props.auth.user.name}
                            </div>
                            <div style={largeText}>
                              {order.task_amazonshline1}
                            </div>
                            <div style={largeText}>
                              {order.task_amazonshcity}
                            </div>
                            <div style={largeText}>
                              {order.task_amazonshcontactph}
                            </div>
                          </div>
                        </div>

                        {/* TO: */}
                        <div style={largeLabelRow}>
                          <div style={{width: '19%'}}>
                            <span style={largeHeadText}>To:</span>
                          </div>
                          <div style={{width: '81%'}}>
                            <div style={largeBoldText}>
                              {order.task_consigneename}
                            </div>
                            <div style={largeWrapText}>
                              {order.task_consaddline1}
                            </div>
                            <div style={largeText}>
                              {order.task_consdistrict}
                            </div>
                            <div style={largeText}>{order.task_conscity}</div>
                            <div style={largeText}>
                              {order.task_conscountryname}
                            </div>
                          </div>
                        </div>

                        {/* order number and number of boxes */}
                        <div style={largeLabelRow}>
                          {/* Reference */}
                          <div style={{width: '19%'}}>
                            <div style={largeHeadText}>Order No:</div>
                            <div style={largeHeadText}>Code:</div>
                            <div style={largeHeadText}>Boxes:</div>
                            <div style={largeHeadText}>Hangers:</div>
                            <div style={largeHeadText}>Contents:</div>
                          </div>

                          <div style={{width: '81%'}}>
                            <div style={largeText}>
                              {order.task_customerorderno || 'na'}
                            </div>
                            <div style={largeText}>na</div>
                            <div style={largeText}>
                              {i + 1} of {order.task_totalshipqty}
                            </div>
                            <div style={largeText}>
                              {order.task_hangers || 'na'}
                            </div>
                            <div style={largeWrapText}>
                              {order.task_details || 'na'}
                            </div>
                          </div>
                        </div>

                        <div style={largeLabelQrcodeRow}>
                          {/* QR Code */}
                          <div style={largeLabelQrcode}>
                            <QRCodeSVG size={200} value={awbNumber} />
                            <span style={blackText}>{awbNumber}</span>
                          </div>
                        </div>

                        <div style={largeLabelBarcodeRow}>
                          {/* barcode */}
                          <div style={largeLabelQrcode}>
                            <Barcode
                              width={1.5}
                              value={awbNumber}
                              logo={logo}
                            />
                          </div>
                        </div>
                      </div>
                      {(count = count + 1) % 2 === 0 && (
                        <div className="page-break" style={largePageBreak} />
                      )}
                    </>
                  ))}
                </>
              ))}
          </div>
        </div>
      ) : this.state.labelSize === 'Small' ? (
        <div className="tcorp-print-container">
          <div ref={this.toPrint} style={printContainer}>
            {orders &&
              orders.map((order, index) => (
                <>
                  {order.task_shipmentpackage.map((awbNumber, i) => (
                    <>
                      <div
                        className="tcorp-label"
                        style={tcorpLabel}
                        key={'box' + i}
                      >
                        {/* <div className="label-card" key={i}> */}
                        <div className="label-card" style={tcorpLabelRow}>
                          {/* logo */}
                          <div style={tcorpLabelLCol}>
                            <img
                              style={logoImage}
                              src={LabelHead}
                              alt="Transcorp Label Header"
                            />
                          </div>
                          {/* data and time */}
                          <div style={tcorpLabelRCol}>
                            <span style={blackText}>
                              {moment(
                                order.task_completeafterdate,
                                'YYYY-MM-DD',
                              ).format('YYYY-MM-DD')}{' '}
                              -&nbsp;
                              {moment(
                                order.task_completebeforetime,
                                'HH:mm',
                              ).format('HH:mm')}
                            </span>
                          </div>
                        </div>

                        {/* Merchant Name */}
                        <div style={tcorpNameRow}>
                          <span style={blackText}>
                            FROM: {this.props.auth.user.name}
                          </span>
                        </div>
                        <div style={barcodeDiv}>
                          {/* QR Code */}
                          <div style={barcode}>
                            <QRCodeSVG size={120} value={awbNumber} />
                          </div>

                          <div style={tcorpLabelRow}>
                            {/* AWB */}
                            <span style={blackText}>{awbNumber}</span>
                          </div>
                        </div>
                        <div style={tcorpNameRow}>
                          {/* Customer Name */}
                          <span style={blackText}>TO:&nbsp;</span>
                          <span style={nameText}>
                            {order.task_consigneename}
                          </span>
                        </div>
                        <div style={tcorpLabelRow}>
                          {/* No. Boxes */}
                          <div style={tcorpLabelLCol}>
                            <span style={blackText}>
                              Box {i + 1} of {order.task_totalshipqty}
                            </span>
                          </div>
                          {/* City */}
                          <div style={tcorpLabelRCol}>
                            <span style={blackText}>{order.task_conscity}</span>
                          </div>
                        </div>
                        <div style={lastLabelRow}>
                          {/* Reference */}
                          <div style={tcorpLabelCityCol}>
                            <span style={blackText}>
                              {order.task_customerorderno}
                            </span>
                          </div>
                          {/* Area/District */}
                          <div style={tcorpLabelAreaCol}>
                            <span style={blackText}>
                              {order.task_consdistrict}
                            </span>
                          </div>
                        </div>
                      </div>
                      {(count = count + 1) % 12 === 0 && (
                        <div className="page-break" style={pageBreak} />
                      )}
                    </>
                  ))}
                </>
              ))}
          </div>
        </div>
      ) : (
        <div className="print-selection">
          <div ref={this.toPrint} style={printStickerContainer}>
            {orders &&
              orders.map((order, index) => (
                <>
                  {order.task_shipmentpackage.map((awbNumber, i) => (
                    <>
                      <div
                        className="tcorp-label"
                        style={stickerLabel}
                        key={'box' + i}
                      >
                        <div className="label-card" style={tcorpStickerRow}>
                          {/* logo */}
                          <div style={tcorpStickerLCol}>
                            <img
                              style={logoImage}
                              src={LabelHead}
                              alt="Transcorp Label Header"
                            />
                          </div>
                          {/* data and time */}
                          <div style={tcorpStickerRCol}>
                            <span style={blackText}>
                              {moment(
                                order.task_completeafterdate,
                                'YYYY-MM-DD',
                              ).format('YYYY-MM-DD')}
                              &nbsp;
                              {moment(
                                order.task_completebeforetime,
                                'HH:mm',
                              ).format('HH:mm')}
                            </span>
                          </div>
                        </div>

                        {/* Merchant Name */}
                        <div style={tcorpStickerNameRow}>
                          <span style={blackText}>
                            FROM: {this.props.auth.user.name}
                          </span>
                        </div>
                        <div>
                          {/* QR Code */}
                          <div style={barcode}>
                            <QRCodeSVG size={120} value={awbNumber} />
                          </div>

                          <div style={tcorpStickerRow}>
                            {/* AWB */}
                            <span style={blackText}>{awbNumber}</span>
                          </div>
                        </div>
                        <div style={tcorpStickerNameRow}>
                          {/* Customer Name */}
                          <span style={blackText}>TO:&nbsp;</span>
                          <span style={nameText}>
                            {order.task_consigneename}
                          </span>
                        </div>
                        <div style={tcorpStickerRow}>
                          {/* No. Boxes */}
                          <div style={tcorpStickerLCol}>
                            <span style={stickerText}>
                              Box {i + 1} of {order.task_totalshipqty}
                            </span>
                          </div>
                          {/* City */}
                          <div style={tcorpStickerRCol}>
                            <span style={stickerText}>
                              {order.task_conscity}
                            </span>
                          </div>
                        </div>
                        <div style={lastStickerLabelRow}>
                          {/* Reference */}
                          <div style={tcorpStickerCityCol}>
                            <span style={stickerText}>
                              {order.task_customerorderno}
                            </span>
                          </div>
                          {/* Area/District */}
                          <div style={tcorpStickerAreaCol}>
                            <span style={stickerText}>
                              {order.task_consdistrict}
                            </span>
                          </div>
                        </div>
                      </div>
                      {(count = count + 1) % 12 === 0 && (
                        <div className="page-break" style={stickerPageBreak} />
                      )}
                    </>
                  ))}
                </>
              ))}
          </div>
        </div>
      );

    return (
      <div className="print-container-scroll">
        <div style={{margin: '0 auto', width: 'fit-content'}}>
          <div className="print-nav">
            <div>
              <Button
                title="back"
                type="button"
                variant="contained"
                className="mt-2 mt-lg-0 mr-2"
                onClick={() => this.props.history.push('/')}
              >
                &nbsp;Back
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button
                    title="Print Table"
                    type="button"
                    variant="contained"
                    color="primary"
                    className="mt-2 mt-lg-0"
                  >
                    <PrintIcon />
                    &nbsp;Print Labels
                  </Button>
                )}
                content={() => this.toPrint.current}
                pageStyle="@page { size: A4; margin: 0mm; } @media print { body { width: 210mm !important; height: 297mm !important; -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                copyStyles
              />
            </div>
            <LabelSizeSwitch
              labelSize={this.state.labelSize}
              handleLabelSize={this.handleLabelSize}
            />
          </div>
          {label}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PrintOrderLabel);

// click check boxes and then click print
// handlePrinting send the orders to be printed to the print component
// the print component will call print for number of boxes and label number of boxes i.e. 1 of 3
