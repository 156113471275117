import React, {Component} from 'react';
import {connect} from 'react-redux';

import TableContainer from '../OrdersTable/TableContainer';
import Spinner from '../common/Spinner';
import {getOrders, logoutUser} from '../../actions/azdanActions';

class Orders extends Component {
  componentDidMount() {
    this.props.getOrders();

    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }
  }

  render() {
    const {order} = this.props;
    return (
      <div className="pt-1">
        {order.orderList === null ? (
          <Spinner />
        ) : (
          <TableContainer orders={order.orderList} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  order: state.order,
});

export default connect(mapStateToProps, {
  getOrders,
  logoutUser,
})(Orders);
