import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';

import Footer from '../layout/Footer';
import {loginUser} from '../../actions/azdanActions';
import Spinner from '../common/LoginSpinner';
import logo from '../../images/transcorpLogo.png';
import './Login.css';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      showPassword: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/orders');
    }
  }

  componentWillUnmount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }

    document.body.style.backgroundImage = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/orders');
    }

    if (nextProps.errors) {
      this.setState({errors: nextProps.errors});
    }
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(user);
  }

  handleClickShowPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {auth, errors} = this.props;

    let formInputs;

    if (auth.loading) {
      formInputs = <Spinner />;
    } else {
      formInputs = (
        <React.Fragment>
          <OutlinedInput
            error={errors ? true : false}
            style={{height: '37px', width: '100%', marginBottom: '15px'}}
            placeholder="Email"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
          />
          <OutlinedInput
            error={errors ? true : false}
            style={{height: '37px', width: '100%'}}
            id="outlined-adornment-password"
            type={this.state.showPassword ? 'text' : 'password'}
            value={this.state.password}
            name="password"
            onChange={this.onChange}
            placeholder="Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  edge="end"
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors && (
            <div className="error" style={{color: '#F44335'}}>
              Invalid login information.
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <div className="Login" data-test="component-login">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 text-center">
              <span className="lead text-center">Log in to your</span>
              <img className="Logo-img" src={logo} alt="TransCorp Logo" />
              <p className="lead text-center mb-4">account</p>
              <form onSubmit={this.onSubmit}>
                {formInputs}

                <Button
                  disabled={auth.loading ? true : false}
                  variant="contained"
                  type="submit"
                  color="primary"
                  value="Login"
                  className="mt-4"
                  style={{width: '100%'}}
                >
                  Login
                </Button>

              </form>
              
            </div>
          </div>
        </div>
        
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.loginErrors,
});

export default connect(mapStateToProps, {loginUser})(Login);
