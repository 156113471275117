import React from 'react';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControlLabel,
  Switch,
  Fab,
  Checkbox,
  TablePagination,
} from '@material-ui/core';

import TableColumns from './TableColumns';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import TablePaginationActions from './TablePaginationActions';
import logo from '../../images/tcorp-favicon2.png';
import generateBadge from '../../utils/generateBadge';
import {toggleTableDense, openEditModal} from '../../actions/azdanActions';
import {formatTime} from '../../helpers';

// a little function to help us with reordering the result
// From react-sortable-hoc sample code
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const styles = (theme) => ({
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    // overflow: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'task_consigneename',
      selected: [],
      selectedOrders: [],
      data: this.props.orders.sort((a, b) =>
        a.task_consigneename < b.task_consigneename ? -1 : 1,
      ),
      columnData: [],
      page: 0,
      rowsPerPage: 50,
    };
  }

  componentWillReceiveProps(nextProps) {
    const columnsObject = JSON.parse(localStorage.getItem('columnsHidden'));

    if (columnsObject !== null && columnsObject !== '') {
      this.setHiddenColumns(columnsObject);
    } else {
      this.setState({columnData: this.props.columnData});
    }
  }

  componentDidMount() {
    const columnsObject = JSON.parse(localStorage.getItem('columnsHidden'));

    if (columnsObject !== null && columnsObject !== '') {
      this.setHiddenColumns(columnsObject);
    } else {
      this.setState({columnData: TableColumns});
    }
  }

  setHiddenColumns = (tableHiddenColumnArr) => {
    const tableColumnsArr = TableColumns;
    const filteredColumns = tableColumnsArr.filter(
      (elem) => !tableHiddenColumnArr.find((id) => elem.id === id),
    );
    this.setState({columnData: filteredColumns});
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const columnData = reorder(
      this.state.columnData,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      columnData,
    });
  };
  // Demo code
  handleWidthChange = (columnId, width) => {
    this.setState((state) => {
      const currentColumns = [...state.columnData];
      const currentColumnIndex = currentColumns.findIndex((column) => {
        return column.id === columnId;
      });
      const columnToChange = currentColumns[currentColumnIndex];
      const changedColumn = {...columnToChange, width};
      currentColumns.splice(currentColumnIndex, 1, changedColumn);
      // Return the unchanged columns concatenated with the new column
      const newState = {
        columnData: currentColumns,
      };
      return newState;
    });
  };

  handleArrayMove = (from, to, oldData) => {
    // guessing this gets replaced by arrayMove method
    const newData = [].concat(oldData);
    from >= to
      ? newData.splice(to, 0, newData.splice(from, 1)[0])
      : newData.splice(to - 1, 0, newData.splice(from, 1)[0]);

    return newData;
  };

  handleReorderColumn = (from, to) => {
    this.setState((state) => {
      return {
        columnData: this.handleArrayMove(from, to, state.columnData),
        data: this.handleArrayMove(from, to, state.data),
      };
    });
  };

  // material-ui code
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({data, order, orderBy});
  };

  // material-ui code
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({
        selectedOrders: [...this.state.data],
        selected: this.state.data.map((order) => order._id),
      });
      return;
    }
    this.setState({selected: [], selectedOrders: []});
  };

  // adds/removes rows have ticked checkboxes to selected
  handleClick = (id) => {
    const {selected} = this.state;

    const selectedIndex = selected.indexOf(id);

    let newSelected = [];
    // -1 theres it isn't in the selected state(un-checked box), so add it
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({
      selected: newSelected,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: event.target.value});
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onOrderClick(order) {
    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }

    this.props.openEditModal(order);
    this.props.clearAlert();
  }

  handleChangeDense = (event) => {
    this.props.toggleTableDense();
  };
  // changes state of data when user enters search that gets passed as props
  componentDidUpdate(prevProps) {
    if (this.props.orders !== prevProps.orders) {
      const data = this.props.orders.sort((a, b) =>
        a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1,
      );
      this.setState({data: data});
    }
  }

  render() {
    const {classes} = this.props;
    const {tableDense} = this.props.order;
    const {data, order, orderBy, selected, rowsPerPage, page} = this.state;

    let emptyRows;

    if (data.length < 10) {
      emptyRows = 10;
    } else if (data.length > rowsPerPage) {
      emptyRows = 0;
    } else {
      emptyRows = data.length;
    }

    return (
      <div className={classes.root}>
        {selected.length > 0 && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            orders={this.state.data}
            selected={this.state.selected}
          />
        )}
        <span className={classes.tableWrapper}>
          <Table
            table-layout="fixed"
            className={classes.table}
            aria-labelledby="tableTitle"
            size={tableDense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              handleReorderColumnData={this.onDragEnd}
              handleResizeColumn={this.handleWidthChange}
              columnData={this.state.columnData}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order, index) => {
                  const isSelected = this.isSelected(order._id);
                  return (
                    <TableRow key={index}>
                      <td>
                        {/* We need to nest the contents of this row to parallel the
                         * use of Droppable in the header and ensure that headers and body line up.*/}
                        <Table
                          style={{display: 'block'}}
                          size={tableDense ? 'small' : 'medium'}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isSelected}
                                  hover="true"
                                  onClick={(event) =>
                                    this.handleClick(order._id)
                                  }
                                  role="checkbox"
                                  aria-checked={isSelected}
                                  tabIndex={-1}
                                  key={order._id}
                                  selected={isSelected}
                                />
                              </TableCell>
                              {this.state.columnData.map((column) => {
                                return column.id ===
                                  'task_completeafterdate' ? (
                                  <TableCell
                                    key={column.id}
                                    paddingleft={'10px'}
                                    width={`${column.width}px` || '100px'}
                                    onClick={() => this.onOrderClick(order)}
                                    className="fade-in pointer"
                                  >
                                    <React.Fragment>
                                      <div
                                        width={`${column.width}px` || '100px'}
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          color: '#3f51b5',
                                        }}
                                      >
                                        {order[column.id]}
                                      </div>
                                    </React.Fragment>
                                  </TableCell>
                                ) : column.id === 'task_status' ? (
                                  <TableCell
                                    key={column.id}
                                    padding="none"
                                    width={`${column.width}px` || '100px'}
                                  >
                                    <div
                                      style={{
                                        width: 'auto',
                                        padding: '2px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {generateBadge(order[column.id])}
                                    </div>
                                  </TableCell>
                                ) : column.id === 'task_created' ? (
                                  <TableCell
                                    key={column.id}
                                    padding="none"
                                    width={`${column.width}px` || '100px'}
                                  >
                                    <div
                                      style={{
                                        width: 'auto',
                                        padding: '2px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {formatTime(
                                        order['timeline'][0].timestamp,
                                      )}
                                    </div>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    padding="none"
                                    width={`${column.width}px` || '100px'}
                                  >
                                    <div
                                      style={{
                                        width: `${column.width}px` || '100px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingLeft: '10px',
                                        color: '#000',
                                      }}
                                    >
                                      {order[column.id]}
                                    </div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </td>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </span>
        <footer className="Footer text-dark d-flex justify-content-between">
          <TablePagination
            className="d-flex"
            component="span"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            rowsPerPageOptions={[50, 500, 1000]}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <div className="displaying-orders">
            <FormControlLabel
              control={
                <Switch
                  checked={tableDense}
                  onChange={this.handleChangeDense}
                  color="primary"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              }
              style={{fontSize: '14px'}}
              label="Show more on table"
            />
            <Fab variant="extended" size="small">
              <img src={logo} alt="tcorp-logo" />
              &nbsp; Displaying: {this.props.order.numOrders} orders
            </Fab>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    order: state.order,
  };
};

export default connect(mapStateToProps, {openEditModal, toggleTableDense})(
  withStyles(styles)(EnhancedTable),
);
