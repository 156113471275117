import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// core components
import EnhancedTableHead from '../components/EnhancedTableHead';
import styles from '../../../assets/jss/material-dashboard-react/components/tableStyle';

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const {tableHead, tableData, collapseData, footerData} = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(0);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableHead.length}
            headCells={tableHead}
          />
        ) : null}
        {collapseData !== undefined ? (
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy)).map(
              (row, rowIndex) => {
                return (
                  <CollapsableRow
                    key={'rowData' + rowIndex}
                    collapseRows={collapseData[row[0]]}
                    headerRow={row}
                    type={collapseData.type}
                  />
                );
              },
            )}
          </TableBody>
        ) : (
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy)).map(
              (row, rowIndex) => {
                return (
                  <TableRow
                    key={'rowData' + rowIndex}
                    className={classes.tableBodyRow}
                  >
                    <TableCell></TableCell>
                    {row.map((colData, colIndex) => {
                      return (
                        <TableCell
                          className={classes.tableCell}
                          key={'colData' + colIndex}
                        >
                          {colData}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              },
            )}
          </TableBody>
        )}
        {footerData !== undefined ? (
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={classes.tableHeadCell}>Total</TableCell>
              {footerData.map((footerCell, footerIndex) => (
                <TableCell
                  key={'footer' + footerIndex}
                  className={classes.tableHeadCell}
                >
                  {footerCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
      </Table>
    </div>
  );
}

const useRowStyles = makeStyles({
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  tableCell: {
    lineHeight: '1.42857143',
    padding: '8px',
    verticalAlign: 'middle',
    width: '130px',
  },
  tableResponsive: {
    width: '100%',
    overflowX: 'auto',
  },
  tableBodyRow: {
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
    width: '100%',
  },
  root: {
    padding: '0',
  },
});

function CollapsableRow(props) {
  const {headerRow} = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.tableBodyRow}>
        <TableCell classes={{root: classes.root}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headerRow.map((header, i) => {
          return (
            <TableCell key={('Collapse', i)} align="left">
              {header}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow className={classes.tableBodyRow}>
        <TableCell style={{padding: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table className={classes.table} aria-label="purchases">
                <TableBody>
                  {props.collapseRows.map((historyRow, index) => (
                    <TableRow
                      key={'historyRow' + index}
                      className={classes.tableBodyRow}
                    >
                      <TableCell
                        style={{paddingLeft: '30px'}}
                        className={classes.tableCell}
                      >
                        {historyRow[props.type]}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {historyRow.task_totalshipqty}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {historyRow.task_bagsreturned}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {historyRow.task_icepacks}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
