import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Tab,
  Tabs,
  Grid,
  Button,
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
} from "@material-ui/core";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";

import Spinner from "../common/SpinnerSmall";
import logo from "../../images/transcorpLogo.png";
import { logoutUser } from "../../actions/authActions";

const useStyles = makeStyles({
  logo: {
    width: 185,
    // height: 43.54,
  },
  selected: {
    "&.Mui-selected": {
      color: "#b7d157",
    },
  },
  root: {
    fontFamily: "Graphik, sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    textTransform: "capitalize",
    opacity: 1,
    "&:hover": {
      color: "#b7d157",
    },
    "&:focus": {
      color: "#b7d157",
    },
  },
  toolbar: {
    background: "#fff",
    color: "#222222",
    boxShadow: "1px 2px 5px 0px rgba(221, 221, 221, 1)",
    zIndex: 5,
  },
  indicator: {
    background: "#fff",
  },
  gutters: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
});

const Navbar = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(0);

  useEffect(() => {
    let path = props.history.location.pathname;
    if (path === "/orders" && value !== 0) setValue(0);
    else if (path === "/activity" && value !== 1) setValue(1);
    // else if (path === "/orderuploads" && value !== 2) setValue(2);
    else if (path === "/reports" && value !== 3) setValue(3);
    else if (path === "/freshdesk" && value !== 4) setValue(4);

    //Start Implement Fresh Widgets
    const script = document.createElement("script");
    script.innerText = `	window.fwSettings={
     
      'widget_id':72000000834
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://widget.freshworks.com/widgets/72000000834.js";
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);
    // End Implement Fresh Widgets

    //Start Implement Fresh Chat
    const scriptchat = document.createElement("script");
    scriptchat.innerText = ` function initFreshChat() { window.fcWidget.init({
        "config": {
          "cssNames": {
             "widget": "custom_fc_frame"
          }
       },
        token: "32597369-0081-47d6-968d-89de3db70297",
        host: "https://wchat.freshchat.com"
          });
        }
    function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1)`;// eslint-disable-line
    document.body.appendChild(scriptchat);

    // End Implement Fresh Chat
  }, [value]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTabSupport = () => {
    window.open("https://transcorp-intl.freshdesk.com");
  };

  const open = Boolean(anchorEl);

  const authLinks = (
    <>
    <div className="App-header">
    <p className="App-header-p">
     <a className="App-header-p" href="https://transcorpuae.suitefleet.com/signin" target="_blank" rel="noopener noreferrer">Login to the new portal from here</a>
    </p>
  </div>
    <Toolbar className={classes.toolbar} classes={{ gutters: classes.gutters }}>
      <Grid justifyContent="flex-start" container>
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="TransCorp Logo" />
        </Link>

        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          onChange={(e, v) => setValue(v)}
          value={value}
          aria-label="Navigation Tabs"
        >
          <Tab
            label="Tasks"
            component={Link}
            to={"/orders"}
            classes={{ selected: classes.selected, root: classes.root }}
          />
          {/* <Tab label={'Calendar'} component={Link} to={'/calendar'} /> */}
          <Tab
            label="Activity"
            component={Link}
            to={"/activity"}
            classes={{ selected: classes.selected, root: classes.root }}
          />

          <Tab
            label="New Orders"
            onClick={() =>
              window.open(
                "https://transcorpuae.suitefleet.com/signin",
                "_blank"
              )
            }
            classes={{ selected: classes.selected, root: classes.root }}
          />

          {/* <Tab
            label="New Orders"
            component={Link}
            to={"/orderuploads"}
            classes={{ selected: classes.selected, root: classes.root }}
          /> */}
          <Tab
            label={"Reports"}
            component={Link}
            to={"/reports/dashboard"}
            classes={{ selected: classes.selected, root: classes.root }}
          />
          <Tab
            label={"Support"}
            onClick={handleClickTabSupport}
            classes={{ selected: classes.selected, root: classes.root }}
          />
          {props.orders.submitInProgress && <Spinner />}
        </Tabs>

        <Grid item xs={3} />
      </Grid>
      <Grid xs={1} item className="d-flex justify-content-end">
        <Button
          color="inherit"
          onClick={handleMenu}
          classes={{ root: classes.root }}
        >
          {props.auth.user.name}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => props.logoutUser()}>
            <MeetingRoomIcon />
            &nbsp; Logout
          </MenuItem>
        </Menu>
      </Grid>
    </Toolbar>
    </>
  );

  const nonAuthLinks = (
    <Toolbar style={{ background: "#fff" }}>
      <Grid justifyContent={"space-between"} container>
        <Grid xs={1} item>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="TransCorp Logo" />
          </Link>
        </Grid>
      </Grid>
    </Toolbar>
  );
  const { isAuthenticated } = props.auth;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {isAuthenticated ? authLinks : nonAuthLinks}
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  orders: state.order,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
