/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import styles from '../../../assets/jss/material-dashboard-react/components/sidebarStyle';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { image, reports } = props;

  var links = (
    <List className={classes.list}>
      {reports.map((report, key) => {
        var listItemClasses = classNames({
          [' ' + classes['blue']]: activeRoute(report.path),
        });

        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(report.path),
        });

        return (
          <NavLink
            to={report.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof report.icon === 'string' ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: report.rtlActive,
                  })}
                >
                  {report.icon}
                </Icon>
              ) : (
                <report.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: report.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={report.rtlActive ? report.rtlName : report.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: report.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  return (
    <div style={{ width: '260px' }}>
      <Drawer
        variant="permanent"
        anchor="left"
        open
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
      >
        <div className={classes.sidebarWrapper}>{links}</div>
        <div
          className={classes.background}
          style={{ backgroundImage: 'url(' + image + ')' }}
        />
      </Drawer>
    </div>
  );
}
