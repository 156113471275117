import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import CSVReader from 'react-csv-reader';
import XLSX from 'xlsx';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonBase,
  Button,
} from '@material-ui/core';
import {
  AccountTree,
  RemoveCircleOutline,
  CheckCircle,
  GridOn,
  BorderColor,
  CloudDownloadOutlined,
} from '@material-ui/icons';
import {green} from '@material-ui/core/colors';

import {ReactComponent as ExcelSvg} from '../../images/excel.svg';

import LinearWithValueLabel from './UploadProgress';
import {clearErrors} from '../../actions/orderActions';
import {resetSuccess} from '../../actions/usersActions';
import {logoutUser} from '../../actions/authActions';
import {
  createCsvOrders,
  openCsvModal,
  getUploads,
  openCreateModal,
  clearOrder,
} from '../../actions/azdanActions';
import tableColumns from '../OrdersTable/TableColumns';
import OrderCsvMapping from './OrderCsvMapping';
import ReportTable from '../Reports/components/Table.js';
import GridItem from '../Reports/components/GridItem.js';
import GridContainer from '../Reports/components/GridContainer.js';
import Card from '../Reports/components/Card.js';
import CardHeader from '../Reports/components/CardHeader.js';
import CardBody from '../Reports/components/CardBody.js';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

const useStyles = makeStyles({
  btnPrimary: {
    backgroundColor: '#0d8cd1',
    margin: '0',
    border: 'none',
    color: '#fff',
    width: '100%',
    fontWeight: '300 !important',
    borderRadius: '4px',
    textTransform: 'uppercase',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
  },
  btnExcel: {
    backgroundColor: '#337236',
    margin: '0',
    border: 'none',
    color: '#fff',
    width: '100%',
    fontWeight: '300 !important',
    borderRadius: '4px',
    textTransform: 'uppercase',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
  },
  btnSecondary: {
    backgroundColor: '#e0e0e0',
    color: '#000',
    margin: '0',
    border: 'none',
    width: '100%',
    fontWeight: '300 !important',
    borderRadius: '4px',
    textTransform: 'uppercase',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#D5D5D5',
    },
  },
  btnOutlined: {
    backgroundColor: 'transparent',
    color: '#0d8cd1',
    margin: '0',
    border: '1px solid #0d8cd1',
    width: '100%',
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '6px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#D5D5D5',
    },
  },
  btnExcelOutlined: {
    backgroundColor: 'transparent',
    color: '#337236',
    margin: '0',
    border: '1px solid #337236',
    width: '100%',
    borderRadius: '4px',
    textTransform: 'uppercase',
    padding: '6px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#D5D5D5',
    },
  },
  tableHead: {
    fontWeight: '500 !important;',
  },
  grid: {
    margin: '0',
  },
});

const OrderUpload = (props) => {
  const classes = useStyles();
  const [, setRefresh] = useState();
  const [chartData, setChartData] = useState([]);
  const {modalState} = props;

  useEffect(() => {
    async function getUploads() {
      await props.getUploads();
    }
    getUploads();
    generateChartData();
  }, []);

  useEffect(() => {
    generateChartData();
  }, [props.order.uploads]);
  // setRefresh is used to update state which repaints DOM with table's newly mapped headings when modal is closed
  useEffect(() => {
    setRefresh(modalState);
  }, [modalState]);

  const generateChartData = () => {
    const data = [];
    props.order.uploads.forEach((upload) => {
      const uploadData = getUploadType(upload);
      data.push([upload.createdAt, uploadData.amount, uploadData.type]);
    });
    setChartData(data);
  };

  const getUploadType = (upload) => {
    if (upload.UI) {
      return {type: 'Single Upload', amount: upload.UI};
    } else if (upload.CSV) {
      return {type: 'CSV/Excel Upload', amount: upload.CSV};
    } else if (upload.API) {
      return {type: 'Uploaded from API', amount: upload.API};
    } else {
      return {type: 'Uploaded from Netsuite', amount: upload.NS};
    }
  };

  const mapExcelFileHeadings = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = async (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header: 1});
        localStorage.removeItem('savedCsvHeaders');
        await props.openCsvModal([data[0]]);
      };
      if (rABS) reader.readAsBinaryString(files[0]);
      // reader.readAsArrayBuffer(file);
    }
  };

  const uploadFile = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = async (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* remove scientific notation that Excel loves to add for some dumb reason */
        Object.keys(ws).forEach(function(s) {
          if (ws[s].w && ws[s].w.includes('+')) {
            ws[s].w = ws[s].v.toString();
          }
        });
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
          defval: '',
        });
        const nData = data.filter((arr) => arr[0]);

        return props.createCsvOrders(nData);
      };
      if (rABS) reader.readAsBinaryString(files[0]);
      // reader.readAsArrayBuffer(file);
    }
  };

  const downloadExcel = () => {
    fetch('/api/users/download', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/text',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        let a = document.createElement('a');
        a.href = url;
        a.download = 'TranscorpExcelUploadDocument.xlsx';
        a.click();
      });
  };

  const userKeys = JSON.parse(localStorage.getItem('savedCsvHeaders')) || '';

  const savedUserHeaders = Object.keys(userKeys);
  const savedAzdanHeaders = Object.values(userKeys);
  const tcorpHeaders = tableColumns.filter((heading) => heading.csvHeading);

  const {tableDense} = props.order;
  const {user} = props.auth;
  const {uploading} = props.csv;

  return (
    <div className="m-0 d-inline d-flex justify-content-start">
      <div className="tcorp-header-text m-4 d-flex flex-column">
        <div>
          <div className="tcorp-header-name">
            <h3 className="pb-1">{user.name}</h3>
          </div>

          <div className="tcorp-header-date mb-3">
            <span>Today is {moment().format('MMM DD, YYYY')}</span>
          </div>

          <div>Name: {user.name}</div>
          <div className="mb-2">Email: {user.email}</div>

          <Button
            data-test="open-create-order"
            variant="contained"
            type="button"
            color="primary"
            classes={{root: classes.btnPrimary}}
            onClick={props.openCreateModal}
          >
            <BorderColor style={{padding: '3px 2px 4px'}} />
            &nbsp; Upload Single Order
          </Button>

          <ButtonBase style={{width: '100%', marginTop: '30px'}}>
            <label
              data-test="excel-upload"
              htmlFor="excel-upload"
              className={`${classes.btnExcel} btn csv-upload-btn d-flex align-items-center justify-content-center`}
            >
              <input
                type="file"
                className="form-control"
                id="excel-upload"
                accept=".xlsx, .xls"
                onChange={(e) => uploadFile(e)}
              />
              <ExcelSvg width="16" fill="#fff" />
              &nbsp;&nbsp;Upload Orders via Excel File
            </label>
          </ButtonBase>
          <ButtonBase style={{width: '100%', marginTop: '18px'}}>
            <label
              htmlFor="excel-headings-map"
              className={`${classes.btnExcelOutlined} btn csv-upload-btn`}
              data-test="map-csv-headers"
            >
              {/* opens modal to create a new order */}
              <input
                type="file"
                className="form-control"
                id="excel-headings-map"
                accept=".xlsx, .xls"
                onChange={(e) => mapExcelFileHeadings(e)}
              />
              <AccountTree style={{width: '15px'}} />
              &nbsp;&nbsp;Map Your Excel Headings
            </label>
          </ButtonBase>

          <ButtonBase
            style={{width: '100%', marginTop: '30px'}}
            disabled={userKeys ? false : true}
          >
            <label
              data-test="csv-upload"
              className={`${
                userKeys ? classes.btnPrimary : classes.btnSecondary
              } btn csv-upload-btn mt-1`}
            >
              <CSVReader
                parserOptions={{skipEmptyLines: true}}
                disabled={uploading}
                onError={() => window.location.reload}
                onFileLoaded={(data, fileInfo) => {
                  props.createCsvOrders(data, fileInfo);
                }}
              />
              <GridOn style={{padding: '2px 2px 4px'}} />
              &nbsp;&nbsp;Upload Orders via CSV
            </label>
          </ButtonBase>

          <ButtonBase style={{width: '100%', marginTop: '18px'}}>
            <label
              className={`${classes.btnOutlined} btn csv-upload-btn`}
              data-test="map-csv-headers"
            >
              {/* opens modal to create a new order */}
              <CSVReader
                onFileLoaded={(data) => {
                  localStorage.removeItem('savedCsvHeaders');
                  props.openCsvModal(data);
                }}
              />
              <AccountTree style={{width: '15px'}} />
              &nbsp;&nbsp;Map Your CSV Headings
            </label>
          </ButtonBase>
        </div>
        <div className="mt-auto">
          <ButtonBase
            style={{width: '100%', marginTop: '18px'}}
            onClick={downloadExcel}
          >
            <label
              className={`${classes.btnOutlined} btn csv-upload-btn`}
              data-test="map-csv-headers"
            >
              {/* <CsvUploadTemplate /> */}
              <CloudDownloadOutlined style={{width: '20px'}} />
              &nbsp;&nbsp;Download Template
            </label>
          </ButtonBase>
        </div>
      </div>

      <div className="upload-container">
        {uploading && <LinearWithValueLabel />}

        <OrderCsvMapping />
        <div style={{marginLeft: '21px'}}>
          <CustomDatePicker />
        </div>
        <div style={{padding: '0 15px'}}>
          <GridContainer classes={{root: classes.grid}}>
            <GridItem xs={6} sm={6} md={6}>
              <Card style={{minHeight: '100%'}}>
                <CardHeader color="warning">
                  <h4 className={classes.cardTitleWhite}>Order Log</h4>
                  <p className={classes.cardCategoryWhite}>
                    View a log of your past orders made via API, CSV and single
                    orders
                  </p>
                </CardHeader>
                <CardBody>
                  <ReportTable
                    tableHeaderColor="warning"
                    tableHead={[
                      'Date & Time',
                      'No of Orders',
                      'Method of Upload',
                    ]}
                    tableData={chartData}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    Your Saved Headings
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    View your saved headings used for file uploads
                  </p>
                </CardHeader>
                <CardBody>
                  {/* Table showing mapping of headings */}
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    size={tableDense ? 'medium' : 'small'}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Mapped
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Your Saved Headings
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          TransCorp Heading
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tcorpHeaders.map((heading, i) => (
                        <TableRow key={'tcorpheader' + i}>
                          <TableCell
                            className={classes.tableCell}
                            scope="key"
                            style={{paddingLeft: '27px'}}
                          >
                            {savedAzdanHeaders.indexOf(heading.id) >= 0 ? (
                              <CheckCircle style={{color: green[500]}} />
                            ) : (
                              <RemoveCircleOutline color="secondary" />
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell} scope="key">
                            {savedAzdanHeaders.indexOf(heading.id) >= 0
                              ? savedUserHeaders[
                                  savedAzdanHeaders.indexOf(heading.id)
                                ]
                              : ''}
                          </TableCell>
                          <TableCell className={classes.tableCell} scope="key">
                            {heading.label}{' '}
                            {heading.required ? (
                              <em style={{color: 'red'}}>*</em>
                            ) : (
                              ''
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.users,
    order: state.order,
    modal: state.modal.csvShow,
    errors: state.errors,
    csv: state.csv,
  };
};

export default connect(mapStateToProps, {
  clearErrors,
  resetSuccess,
  logoutUser,
  openCsvModal,
  createCsvOrders,
  getUploads,
  openCreateModal,
  clearOrder,
})(OrderUpload);
